import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Box, Button, Heading, Text, VStack, Input, useToast, Select, Flex, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useAuth } from "../utils/auth/AuthContext";

const Home = () => {
  const [socket, setSocket] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const [output, setOutput] = useState('');
  const [isGameRunning, setGameRunning] = useState(false);
  const [isGamePaused, setGamePaused] = useState(false);
  const [serverStatus, setServerStatus] = useState(''); // utilisé
  const [maps, setMaps] = useState([]);
  const [saves, setSaves] = useState([]);
  const [selectedMap, setSelectedMap] = useState('');
  const [selectedSave, setSelectedSave] = useState('');
  const [loadedMap, setLoadedMap] = useState(''); // utilisé
  const [loadedSave, setLoadedSave] = useState(''); // utilisé
  const [command, setCommand] = useState('');
  
  const toast = useToast();
  const { userRole } = useAuth();
  const isAdmin = userRole === "admin"; // utilisé pour lignes de commande directes

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    const newSocket = io(process.env.REACT_APP_URL_BACKEND, {
      path: '/socket.io',
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSocket(newSocket);

    newSocket.on('connect', () => setSocketConnected(true));
    newSocket.on('disconnect', () => setSocketConnected(false));

    newSocket.on('gameOutput', (data) => setOutput((prev) => `${prev}\n${data}`));
    newSocket.on('gameStatus', ({ isGameRunning, isGamePaused, mapsloaded, savesloaded }) => {
      setGameRunning(isGameRunning);
      setGamePaused(isGamePaused);
      setLoadedMap(mapsloaded || '');
      setLoadedSave(savesloaded || '');
    });

    newSocket.on('serverStatus', (data) => setServerStatus(data)); // Server status utilisé

    newSocket.emit('getMaps');
    newSocket.on('mapsList', (mapsData) => setMaps(mapsData));
    newSocket.emit('getSaves');
    newSocket.on('savesList', (savesData) => setSaves(savesData));

    return () => newSocket.close();
  }, []);

  const handleSendCommand = () => {
    if (command.trim() === '') {
      toast({
        title: 'Command Error',
        description: 'Command cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    socket.emit('executeCommand', command);
    setCommand('');
  };

  const handleSelectMap = (e) => {
    const map = e.target.value;
    setSelectedMap(map);
    setSelectedSave(''); // Clear save if a map is selected
    toast({ title: `Map selected: ${map}`, status: 'info' });

    // Ouvrir la modale pour confirmation
    setModalType('map');
    onOpen();
  };

  const handleSelectSave = (e) => {
    const save = e.target.value;
    setSelectedSave(save);
    setSelectedMap(''); // Clear map if a save is selected
    toast({ title: `Save selected: ${save}`, status: 'info' });

    // Ouvrir la modale pour confirmation
    setModalType('save');
    onOpen();
  };

  const handleStartGameWithMap = () => {
    if (selectedMap) {
      socket.emit('startGameWithMap');
      toast({ title: 'Game starting with map...', status: 'info' });
      onClose();
    } else {
      toast({ title: 'No map selected.', status: 'error' });
    }
  };

  const handleLoadSave = () => {
    if (selectedSave) {
      socket.emit('startGameWithSave');
      toast({ title: 'Loading save...', status: 'info' });
      onClose();
    } else {
      toast({ title: 'No save selected.', status: 'error' });
    }
  };

  const handleStopGame = () => {
    socket.emit('stopGame');
    setGameRunning(false);
  };

  const handlePauseResumeGame = () => {
    if (isGamePaused) {
      socket.emit('resumeGame');
    } else {
      socket.emit('pauseGame');
    }
  };

  return (
    <VStack spacing={4} align="stretch" p={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h1" size="lg">Mindustry Server Control</Heading>
        <Box borderRadius="full" width="10px" height="10px" bg={socketConnected ? 'green.500' : 'red.500'} />
      </Flex>

      {/* Afficher le statut du serveur */}
      <Box p={4} border="1px solid #ccc" borderRadius="md">
        <Heading as="h3" size="md">Server Status</Heading>
        <Text>{serverStatus || 'No status available.'}</Text> {/* Statut du serveur affiché */}
      </Box>

      {/* Afficher les sélecteurs uniquement si le jeu n'est pas en cours */}
      {!isGameRunning && (
        <>
          <Box p={4} border="1px solid #ccc" borderRadius="md">
            <Heading as="h3" size="md">Available Maps</Heading>
            <Select placeholder="Select a map" value={selectedMap} onChange={handleSelectMap}>
              {maps.map((map, index) => (
                <option key={index} value={map}>{map}</option>
              ))}
            </Select>
          </Box>

          <Box p={4} border="1px solid #ccc" borderRadius="md">
            <Heading as="h3" size="md">Available Saves</Heading>
            <Select placeholder="Select a save" value={selectedSave} onChange={handleSelectSave}>
              {saves.map((save, index) => (
                <option key={index} value={save}>{save}</option>
              ))}
            </Select>
          </Box>
        </>
      )}

      {isGameRunning && (
        <Box>
          <Text>Current Map: {loadedMap || 'No map loaded'}</Text> {/* Carte chargée affichée */}
          <Text>Current Save: {loadedSave || 'No save loaded'}</Text> {/* Sauvegarde chargée affichée */}
          <Button onClick={handlePauseResumeGame} colorScheme="orange" isDisabled={!socketConnected}>
            {isGamePaused ? 'Resume Game' : 'Pause Game'}
          </Button>
          <Button colorScheme="red" onClick={handleStopGame} ml={2} isDisabled={!socketConnected}>
            Stop Game
          </Button>
        </Box>
      )}

      <Box p={4} border="1px solid #ccc" borderRadius="md" minHeight="200px" maxHeight="300px" overflowY="auto">
        <Text whiteSpace="pre-wrap">{output}</Text>
      </Box>

      {/* Commandes admin directes */}
      {isAdmin && (
        <Box>
          <Heading as="h3" size="md">Admin Commands</Heading>
          <Input
            placeholder="Enter command [admin only]"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            size="md"
            isDisabled={!socketConnected || !isAdmin}
          />
          <Button colorScheme="blue" onClick={handleSendCommand} isDisabled={!socketConnected || !isAdmin}>
            Send Command
          </Button>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalType === 'map' ? `Do you want to start the game with the map "${selectedMap}"?` : `Do you want to load the save "${selectedSave}"?`}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalType === 'map' ? handleStartGameWithMap : handleLoadSave}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Home;
